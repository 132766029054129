
.component {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: auto;
  padding: 10px;
  /* color: red; */
  /* align-items: center; */
  /* background-color: red; */
}

/* @media only screen and (max-width: 600px) {
  .main {
    margin: 0;
  }
} */

