.component {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 1rem; */
  padding: 2rem;
}
.component a {
  text-decoration: underline;
}
.logo {
  width: 60px;
}
.light {
  width: 30px;
  cursor: pointer;
  margin: 0 1rem 1rem 1rem;
}
.dark {
  width: 30px;
  cursor: pointer;
  margin: 0 1rem 1rem 1rem;
}
.header {
  margin: 0;
  margin-right: auto;
  font-weight: 800;
  font-size: 1.2rem;
}
.versions {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
}
.bar {
  margin-left: 5px;
  margin-right: 5px;
}
.version {
  margin-right: 5px;
}
