/* .component {
  margin: 15px;
  color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.row .bar {
  margin-left: 5px;
  margin-right: 5px;
}
.row a,
.row a:visited {
  color: var(--link-color);
}
.khan {
  margin-left: 10px;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sce {
  width: 30px;
  margin: 5px;
  border-radius: 4px;
}
.telegram {
  width: 30px;
  margin: 5px;
}
.discord {
  width: 30px;
  margin: 5px;
}
.youTube {
  width: 30px;
  margin: 5px;
} */

.wrapper {
  /* relative bg-emerald-50 pt-8 mt-20 pb-6 */
  position: relative;
  background: var(--footer-wave-bottom);
  padding-top: 5.5rem;
  margin-top:3.5rem;
  padding-bottom: 3rem;
}

.orderlink {
  display: flex;
  flex-wrap: wrap;
  
  margin-bottom: 1rem;
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  /* align-items: center; */
  /* margin-right: 1.5rem; */
  /* margin-left: ; */
  /* align-items: ; */
  /* align-items: flex-end; */
}

.wave {
  /* bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20
   */
  bottom: auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  margin: -2.5rem 0 0 0;
}

.wavetop {
  /* text-emerald-100 fill-current */
  color: var(--footer-wave-top);
  fill: currentColor;
}

.wavebottom {
  /* text-emerald-100 fill-current */
  color: var(--footer-wave-bottom);
  fill: currentColor;
}

.prelinks {
  /* "w-full lg:w-6/12 px-4" */
  /* width: 15vw; */
  /* display: flex;
  flex-wrap: wrap; */
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  /* justify-content: space-around; */

}

.container {
  /* container mx-auto px-4 */
  /* display: flex; */
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container hr {
  /* my-6 border-gray-400 */
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 2px solid var(--footer-text);
}

.ending {
  /* flex flex-wrap items-center md:justify-between justify-center */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: bottom; */
  /* align-items: left; */
  /* width: 100%; */
  color: var(--footer-text);

}

.text {
  /* "flex flex-wrap" */
  display: flex;
  flex-wrap: wrap;
}

.headings {
  /* "w-full lg:w-6/12 px-4" */
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.headings h4 {
  /* "w-full lg:w-6/12 px-4" */
  font-size: 2.2rem;
  line-height: 2rem;
  color: var(--footer-text);
}
.headings h5 {
  /* "w-full lg:w-6/12 px-4" */
  font-size: 1rem;
  line-height: 1.2rem;
  color: var(--footer-text);
}

.containerbutton {
  /* "mt-6 mb-6" */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.buttonstyle {
  /* `bg-white ${item.style} shadow-xl shadow-emerald-300/50   font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3` */
  /* background-color: var(--color); */
  flex: auto;
  background-color: var(--footer-wave-top);
  font-size: large;
  color: var(--footer-text);
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  border-radius: 9999px;
  outline: none;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 0.70rem;
}

*, ::after {
  border-color: #e5e7eb;
  box-sizing: border-box;
}

* {
  border-style: solid;
  border-width: 0;
}



.inherited_styles_for_exported_element {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5;
  tab-size: 4;
}


::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  appearance: button;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}


@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
  .headings {
    width: 50%;
  }
  .prelinks {
    width: 20%;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}


.underline {
  text-decoration-line: underline;
}

.shadow-xl {
  box-shadow: transparent 0 0 0 0, transparent 0 0 0 0, rgba(110, 231, 183, .5) 0 20px 25px -5px, rgba(110, 231, 183, .5) 0 8px 10px -6px;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .md\:w-4\/12 {
    width: 57.3333%;
  }

  .md\:justify-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
