.component {
  /* set width for auto-margin to be same for 0.5 and 0.6 home page */
  width: 420px;
}

.component ul {
  list-style: disc;
  padding: 10px;
}

.updates {
  padding: 5px;
  padding-left: 15px;
  border-radius: 5px;
}

.category {
  color: var(--link-color);
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: large;
  text-decoration: underline;
}

.label {
  margin-left: 10px;
}

.header a {
  text-decoration: underline;
  line-height: normal;
}
.header h1 {
  text-decoration: underline;
  line-height: normal;
  font-weight: 400;
}
.subHeader {
  font-weight: 400;
  margin-top: 1.5rem;
}

.youTube {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 0;
}

.youTubeLogo {
  width: 30px;
  padding-right: 10px;
}

.intro {
  background-color: var(--code-bg);
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
  border-color: var(--shadow-color);
  border-style: solid;
  border-width: 3px;
  /* box-shadow: 2px 1px 1px 1px var(--shadow-color); */
}

.intro p {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ----------------------------------------------------------------- */



.card {
  position: relative;
  /* margin: 300px auto 0;
  width: 500px;
  height: 250px;
  background: linear-gradient(0deg, #000, #272727); */


  /* margin: 5px; */
  margin-bottom: 3rem;
  margin-top: 2rem;
  background: var(--code-bg);
  height: var(--card-height);
  padding: 2px 10px 2px 10px;
  position: relative;
  border-radius: 6px;
}

.card:before,
.card:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 6px;
  background: linear-gradient(45deg,
      var(--animation-color-first),
      var(--animation-color-second),
      var(--animation-color-third),
      var(--animation-color-four),
      var(--animation-color-five),
      var(--animation-color-six),
      var(--animation-color-seven),
      var(--animation-color-eight),
      var(--animation-color-nine),
      var(--animation-color-ten));
  background-size: 300%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

.cardForImage:after,
.cardForImage:before {
  content: '';
  position: absolute;
  left: -2px;
  top: -4px;
  /* margin: 12px; */
  border-radius: 6px;
  background: linear-gradient(45deg,
      var(--animation-color-first),
      var(--animation-color-second),
      var(--animation-color-third),
      var(--animation-color-four),
      var(--animation-color-five),
      var(--animation-color-six),
      var(--animation-color-seven),
      var(--animation-color-eight),
      var(--animation-color-nine),
      var(--animation-color-ten));
  background-size: 300%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.card:after {
  margin-top: 1.0rem;
  padding-top: 1.0rem;
  filter: blur(1rem);
  opacity: 30%;
  rotate: 0px;
  border-radius: 3rem;
}







@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 23rem;
  /* --card-width: calc(var(--card-height) / 1.5); */
}

.cards {

  margin: 5px;
  margin-bottom: 5rem;
  margin-top: 3rem;
  background: var(--code-bg);
  height: var(--card-height);
  padding: 2px 10px 2px 10px;
  position: relative;
  border-radius: 6px;



  /* background: rgba(243, 243, 243, 0.99); */
  /* opacity: ; */
  /* width: var(--card-width);  */
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  /* display: flex; */
  /* font-size: 1.5em; */
  /* color: var(--color); */
  /* cursor: pointer; */
  /* font-family: cursive; */
}


.cardForImage {
  width: min(100vw - 100px, 1000px);
  margin: min(auto, 100px);
  /* margin: min(50px, 500px); */
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  /* background: var(--code-bg); */
  /* width: var(--card-width);  */
  /* height: var(--card-height);  */
  /* padding: 0px 5px 0px 5px; */
  position: relative;
  border-radius: 6px;
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  /* display: flex; */
  /* font-size: 1.5em; */
  /* color: var(--color); */
  /* cursor: pointer; */
  /* font-family: cursive; */
}

.cardForImage p {
  position: absolute;
  text-align: center;
  color: #20C20E;
  font-weight: 700;
  top: 2rem;
  left: 2rem;
}

.card a {
  /* width: var(--card-width);  */
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  /* display: flex; */
  /* font-size: 1.5em; */
  color: var(--link-color);
  cursor: pointer;
  font-family: monospace;
}


/* @property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
} */

.cards::before {
  content: "";
  width: 102%;
  height: 102%;
  border-radius: 12px;
  background-image: linear-gradient(var(--rotate), var(--animation-color-first), var(--animation-color-second), var(--animation-color-third));
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -1%;
  -webkit-animation: spin 7.5s linear infinite;
  animation: spin 7.5s linear infinite;
}

.cardForImagesss::before {
  content: "";
  width: 100%;
  height: 102%;
  border-radius: 12px;

  background-image: linear-gradient(var(--rotate), var(--animation-color-first), var(--animation-color-second), var(--animation-color-third));
  position: absolute;
  z-index: -3;
  top: -1.5%;
  left: 0%;
  animation: spin 7.5s linear infinite;
  -webkit-animation: spin 7.5s linear infinite;
}

.cards::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) /14);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: auto;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  filter: blur(calc(var(--card-height) / 6));
  -webkit-filter: blur(calc(var(--card-height) / 6));
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(var(--rotate), var(--animation-color-first), var(--animation-color-second), var(--animation-color-third));
  opacity: 1;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  -webkit-animation: spin 7.5s linear infinite;
  animation: spin 7.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
    /* transform: rotate(0deg); */
  }

  100% {
    /* transform: rotate(360deg); */
    --rotate: 360deg;
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
    /* --rotate: 0deg; */
  }

  100% {
    transform: rotate(360deg);
    /* --rotate: 360deg; */
  }
}

.img {
  width: min(100vw - 100px, 1000px);
  border-radius: 5px;
  /* z-index: -2; */
  /* margin: auto; */
  /* padding: 10px; */
  /* width: 95vw; */
}

/* @media only screen and (min-width: 1040px) {
  .img {
    margin: auto;
    padding: 10px;
    width: 75vw;
  }
} */
@media only screen and (max-width: 720px) {
  .cardForImage p {
    font-size: small;
    left: 1.5rem;
  }
}

@media only screen and (max-width: 500px) {
  .cardForImage p {
    font-size: xx-small;
    left: 1rem;
  }
}


/* ############################## */

.cta {
  background: var(--code-bg);
  /* border: 1px solid #e1e4e8; */
  /* shadow  */
  box-shadow: 0 1px 3px var(--shadow-color), 0 1px 2px var(--shadow-color);
  /* border-radius: 3px; 
  padding: 10px;  */
  /* border:#152b48 1px solid; */
  border-radius: 3px;
  padding: 1rem;
  margin: 16px 0;
  color: var(--color)
}


.ctaText h3 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.25;
  color: var(--color)
}

.ctaText {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
  color: var(--color)
}

.ctaButton {
  background-image: linear-gradient(90deg, var(--animation-color-first), var(--animation-color-second), var(--animation-color-third));
  

  /* Shadow to make it stand out  */
  box-shadow: 0 1px 0 rgba(1, 1, 1, 0.1);
  /* padding: 8px 12px;  */
  text-align: center;



  /* border: 1px solid rgba(27, 31, 35, 0.2); */
  border-radius: 3px;
  /* color: #fff; */
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

}


/* animation Hover ctabutton */
.ctaButton:hover {
  background-image: linear-gradient(90deg, var(--animation-color-third), var(--animation-color-second), var(--animation-color-first));
  background-color: #fff; 
  color: #fff; 
  /* border: 1px solid rgba(27, 31, 35, 0.5);  */
  /* box-shadow: 0 0 0 1px rgba(3, 102, 214, 0.3);  */
  transition: all 0.25s ease-in-out; 
  transform: scale(1.05); 
}

/* .ctaButton:hover {
  background-image: linear-gradient(90deg, var(--animation-color-third), var(--animation-color-second), var(--animation-color-first));

  box-shadow: 0 1px 0 rgba(1, 1, 1, 0.1);
  text-align: center;



  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

} */

/* .cta BUtton animation */